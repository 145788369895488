h1 {
  font-weight: 700;
  font-size: 2rem;
}

.App {
}

.mantine-TextInput-root {
  width: 100%;
}

input:focus::-webkit-input-placeholder {
  color: transparent !important;
}
input:focus::-moz-placeholder {
  color: transparent !important;
}
input:focus:-moz-placeholder {
  color: transparent !important;
}
